<template>
  <div>
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ChartData from '../chart-data.js'

export default {
  name: 'Chart',
  data() {
    return {
      ChartData: ChartData
    }
  },
  mounted() {
    const ctx = document.getElementById('chart');
    new Chart(ctx, this.ChartData);
    console.log('test')
  }
}
</script>
<style></style>


<template>
  <div>
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid col-10">
        <a class="nav-title" href="/">BUY AND SELL CRYPTOCURRENCY / TOKENS</a>
        <div class="d-flex">
          <div class="control">
            <a class="button">
              <span @click="loginModal">{{ loginLabel }}</span>
            </a>
          </div>
          <div class="control">
            <a class="button">
              <span @click="signupModal">{{ signupLabel }}</span>
            </a>
          </div>
        </div>
      </div>
    </nav>

   <div>
      <Login @close="loginModal" v-if="showLoginModal" @openSignUp="signupModal()"> </Login>
    </div>

    <div>
      <Signup @close="signupModal" v-if="showSignupModal" @openLogin="loginModal()"> </Signup>
    </div>

   
  </div>
</template>


<script>
import Signup from "../components/modals/Signup.vue";
import Login from "../components/modals/Login.vue";

export default {
  name: "Header",
  components: {
    Signup,
    Login,
  },
  data() {
    return {
      isUserLoggedIn: "account login",
      isUserSignedUp: "create an account",
      loginLabel: "LOG IN",
      signupLabel: "SIGN UP",
      showLoginModal: false,
      showSignupModal: false,

    };
  },

  methods: {
    loginModal() {
      this.showLoginModal = !this.showLoginModal;
      this.showSignupModal = false
    },
    signupModal() {
      this.showSignupModal = !this.showSignupModal;
      this.showLoginModal = false
    },

  },
};
</script>

<style>
.navbar {
  background: rgb(2, 11, 54);
  background-image: linear-gradient(
    to right,
    #862f7e 0%,
    #681f6f 38%,
    #551466 73%,
    #4e1163 97%
  ) !important;
  width: 100%;
  height: auto;
}
.nav-title {
  padding: 10px;
  font-family: "Fira Sans Condensed", sans-serif;
  color: white;
  text-decoration: none;
}

.control a {
  padding: 10px;
  background: transparent;
  border: solid transparent;
  color: white !important;
  font-weight: lighter;
  cursor: pointer;
  text-decoration: none;
}
.control:focus {
  outline: none;
}
#forgotPassword {
  float: right;
  margin: 0 20px;
}
</style>

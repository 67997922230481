<template>

   <div class="backdrop">
     <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Are you sure?</h4>
          </div>
          
        </div>
        <div class="p-5">
          <div class="mx-auto">
            
            <h6 class="pt-2">You are about to logout.</h6>
            <h6>Please confirm.</h6>
  
            <div class="d-flex justify-content-center mt-4 mb-4">
                <button class="btn btn-primary text-uppercase mr-sm-2" @click="logout()">Yes</button>
                <button class="btn btn-secondary text-uppercase mr-sm-2" @click="closeModal()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'LogoutModal',
          data(){
              return {

              }
          },
          methods:{
            closeModal() {
                this.$emit("close");
            },
             logout: function(){
                this.axios.get('/cx/logout')
                .then((response) => { 
                  
                  window.location.href = "/#/";
                    
                }).catch((err) => { 
                  console.log(err)

                })
            },
           
          }

      }

  </script>
  <style scoped>
  .b-modal{
    width: 400px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
      background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }
   @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: 175px auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
  </style>
<template>
  <div>
     <div class="cart-header modal-header">
              <div class="cart-title modal-title">
                <h3>Current price expires in</h3>
                <b-button
                  variant="outline-dark"
                  class="timer mb-1 d-flex"
                  size="sm"
                  aria-hidden="true"
                  id="timer"
                  ><b-icon icon="alarm"></b-icon></b-button>
              </div>
      </div>
      <PriceReset v-if="lockedPriceReset"/>

    
  </div>
</template>

<script>
import PriceReset from './PriceReset.vue'

export default {

  name:'Timer',
   components: {PriceReset},
      data(){
        return{
           cartTimerSeconds: 450,
           lockedPriceReset: false,
           isEditing: false,
           timer: "",
           timerInSeconds: 0,
            timerInMinutes: 0,
            loaded: false
      }
    },
    computed:{
       _seconds:() => 1000,
        _minutes(){
          return this._seconds * 60
        },
    },
    methods: {
        timerStarts(){
       
          if(typeof localStorage.getItem("min") !== 'undefined' && typeof localStorage.getItem("sec") !== 'undefined' && localStorage.getItem("min")!= null && localStorage.getItem("sec")!= null ){
              var min = localStorage.getItem("min");
              var sec = localStorage.getItem("sec");
              
          }
         
          //  if(typeof localStorage.getItem("sec") == 0){
          //       this.lockedPriceReset = true
          // }
          else {
            console.log("c2");
            var min = +15 ;
            var sec = "0"+0;
          }
          var time;

          setInterval(function()
          {

              localStorage.setItem("min", min);
              localStorage.setItem("sec", sec);
              time = min +" : "+ sec;
              document.getElementById("timer").innerHTML = time ;
             
              if(sec == 0)
              
              {
                  if(min !=0)
                  {
                      min--;
                      sec=59;
                      if(min < 10)
                      {
                          min="0"+min;
                      }
                  }
                  
              }
              else
              {
                  sec--;
                  if(sec < 10)
                  {
                      sec="0"+sec;
                  }
              }
              this.lockedPriceReset = true
          },1000);
        
        },
       

                
          timerInCart(){
                    let counterInterval = setInterval(() => {
                      this.cartTimerSeconds--;
                      // console.log(this.cartTimerSeconds)
                      

                      if(this.cartTimerSeconds == 0) {
                        clearInterval(counterInterval)
                        this.lockedPriceReset = true
                        this.cartTimerSeconds = 450
                        localStorage.setItem("sec", this.cartTimerSeconds)
                        console.log(localStorage.setItem("sec", this.cartTimerSeconds))
                      }
                    }, 1000)

                    history.pushState(null, null, location.href);
                      window.onpopstate = function () {
                          history.go(1);
                  };
              },
              timerIn(){
                  const timer = setInterval(() => {
                  const now = new Date();
                  const end = this.cartTimerSeconds;
                  const distance = end.getTime()  - now.getTime();

                  if(distance< 0){
                    clearInterval(timer);
                    return;
                  }
                  const minutes = Math.floor ((distance % this._hours) / this._minutes);
                  const seconds = Math.floor ((distance % this._minutes) / this._seconds);

                  this.timerInMinutes = minutes < 10 ? "0" + minutes: minutes;
                  this.timerInSeconds = seconds < 10 ? "0" + seconds: seconds;
                  this.loaded = true;
                }, 1000)
              },

              timeFormat(seconds) {
                let date = new Date(0);
                date.setSeconds(seconds);
                return date.toISOString().substr(14, 5);
              },
              
              preventNav(event) {
                  if (!this.isEditing) return
                  event.preventDefault()
                  event.returnValue = ""
              },
          },
    
          beforeMount(){
            window.addEventListener("beforeunload", this.preventNav)
           
          },
           beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
            
       
            
          },

          beforeRouteLeave(to, from, next) {
            if (this.isEditing) {
              if (!window.confirm("Leave without saving?")) {
                return;
              }
            }
            next();
          },

        mounted(){
        //this.timerInCart();
        this.timerStarts();
        //this.timerIn();
        },

  
};
</script>

<style>
</style>
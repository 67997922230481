import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Failed from "../components/modals/Failed.vue";
import SetupProfile from "../components/modals/SetupProfile.vue";
import Dashboard from "../components/Dashboard.vue";
import CreateProduct from "../components/CreateProduct.vue";
import Kyc from "../components/modals/Kyc.vue"



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/createProduct",
    name: "CreateProduct",
    component: CreateProduct,
  },
  {
    path: "/failed/:txnId",
    name: "Failed",
    component: Failed
  },
  {
    path: "/setup-profile/",
    name: "SetupProfile",
    component: SetupProfile,
    props: true,
  },
  {
    path: "/store/:userId",
    name: "Dashboard",
    component: Dashboard,
    props: true,
    },
    {
      path: "/cart/:userId",
      name: "Cart",
      component: () => import(/* webpackChunkName: "cart" */ "../components/Cart.vue"),
      props: true,
    },
    {
      path: "/checkout/:userId",
      name: "Checkout",
      component: () =>
        import(/* webpackChunkName: "checkout" */ "../components/Checkout.vue"),
      props: true,
    },
    {
      path: "/banking/:txnId",
      name: "Bank",
      component: () =>
        import(/* webpackChunkName: "checkout" */ "../components/modals/Bank.vue"),
      props: true,
    },
    {
      path: "/successful/:txnId",
      name: "Successful",
      component: () =>
        import(/* webpackChunkName: "successful" */ "../components/Successful.vue"),
      props: true,
    },
    {
      path: "/txhistory/:userId",
      name: "TransactionHistory",
      component: () =>
        import(/* webpackChunkName: "successful" */ "../views/TransactionHistory.vue"),
      props: true,
    },
    {
      path: "/complete-kyc/:userId",
      name: "Kyc",
      component: () =>
        import(/* webpackChunkName: "successful" */ "../components/modals/Kyc.vue"),
      props: true,
    },


];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
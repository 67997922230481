<template>
<div class="main">
   <PageLoader/>
  <div class="main-container">
  
    <div>
      <h1 class="title">Crypto e-commerce you can trust</h1>
    </div>
    <div class="container">

          <div class="currency col-3">
            <p class="mx-4">Currency: </p><select name="currency" @change="onChange($event)" v-model="currency" class="search-wrapper form-select form-control" cols="1">
                <option disabled>Currency</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="AUD">AUD</option>
                <option value="USD">USD</option>
                <option value="JPY">JPY</option>
                <option value="KRW">KRW</option>
                <option value="PHP">PHP</option>
            </select>
        </div>

         
    </div>
     
  </div>
  <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'EUR'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in euroArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>
        <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'GBP'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in gbpArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>

  <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'AUD'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in audArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>

   <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'USD'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in usdArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>
   <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'JPY'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in jpyArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>
   <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'KRW'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in krwArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>
    <div class="main-wrapper">
          <div class="wrapper container" v-if="this.currency == 'PHP'">
                    <div class="flex-container d-flex">
                        <div class="flex-box d-flex flex-wrap">
                            <Product 
                            v-for="rate in phpArray"
                            :key="rate.id"
                            :rate="rate"/>
                            
                        </div>
                    </div>
                    
            </div>
  </div>
                <!-- <Chart style="margin-top: 10px;"/> -->


                 <!-- <div class="pt-5 col-md-12 text-white">
                    <h6>POPULAR CRYPTOCURRENCIES</h6>
                        <div class="table-responsive">
                                <table class="table">
                                <thead>
                                    <tr >
                                        <th>NAME</th>
                                        <th>PRICE</th>
                                        <th>1h%</th>
                                        <th>24%</th>
                                        <th>7d%</th>
                                        <th>Market Cap</th>
                                        <th>Volume(24h)</th>
                                        <th>Circulating Supply</th>
                                        <th>Last 7 days</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
              
            </div> -->
    
  
     
    
     
</div>
</template>
<script>
import viewTrending from "./viewTrending.vue"
import numeral from 'numeral'
import Login from "../components/modals/Login.vue";
import Signup from "../components/modals/Signup.vue";
import Product from '../views/Product.vue'
import { Marquee, Slide } from "vue-marquee-component"
import Chart from '../components/Chart.vue'
import PageLoader from '../components/PageLoader.vue'



export default {
   components: {
      [Marquee.name]: Marquee,
    [Slide.name]: Slide,
    viewTrending,
     Login,
     Signup,
     Product,
       Chart,
       PageLoader
   
  },
  data() {
    return {
      search: '',
      currency: "EUR",
      isCryptoActive: true,
      sourceCurrency: '',
      toCurrency:'',
      euro:[],
      usd:[],
      krw:[],
      jpy:[],
      gbp:[],
      php:[],
      aud:[],
      euroArray:[],
      usdArray:[],
      gbpArray:[],
      jpyArray:[],
      krwArray:[],
      phpArray:[],
      audArray:[],
    showLoginModal: false,
    showSignupModal: false,
      
    };
  },
   computed: {
    init() {
        return this.$store.state.init;
      },
  },
  methods: {
    onChange(event){
      this.currency = event.target.value;
    },
    loginModal() {
      this.showLoginModal = !this.showLoginModal;
      this.showSignupModal = false
    },
    signupModal() {
      this.showSignupModal = !this.showSignupModal;
      this.showLoginModal = false
    },
    formatAmount: function(amount) {
			return numeral(amount).format('0,0.00') 
		},
    getEurRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'EUR'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                  this.euro = response.data
                  this.euroArray.push(this.euro)
                 
		            }).catch((err) => {

		            })
    },
    getGbpRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'GBP'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                  this.gbp = response.data
                  this.gbpArray.push(this.gbp)
                  
		            }).catch((err) => {

		            })
    },
    getUsdRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'USD'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.usd = response.data
                  this.usdArray.push(this.usd)
		            }).catch((err) => {

		            })
    },
      getJpyRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'JPY'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.jpy = response.data
                  this.jpyArray.push(this.jpy)
		            }).catch((err) => {

		            })
    },
     getKrwRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'KRW'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.krw = response.data
                  this.krwArray.push(this.krw)
		            }).catch((err) => {

		            })
    },
    getPhpRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'PHP'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.php = response.data
                  this.phpArray.push(this.php)
		            }).catch((err) => {

		            })
      },
      getAudRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'AUD'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.aud = response.data
                  this.audArray.push(this.aud)
		            }).catch((err) => {

		            })
      },
      // getLatestData(){
        
		   

		  //       const options = {
		  //         method: 'GET',
		  //         headers: { 'X-CoinAPI-Key': '22E1109F-FB1E-4E66-AFB1-F837F2D90212' 
      //          },
		  //         params: {
      //           symbol_id: 'BITSTAMP_SPOT_BTC_USD',
      //           period_id: '1MIN',
                
      //         },
		  //         url:'https://rest.coinapi.io/v1/ohlcv/',
		  //       };


			// 		 this.axios(options)
		  //           .then((response) => {  
      //             console.log(response.data)
		  //           }).catch((err) => {

		  //           })
       
      // }
      
      // reloadPage(){
      //   window.setTimeout( function() {
      //       window.location.reload();
      //     }, 59000);
      // },
  
  },
  mounted(){
    //this.reloadPage();
    // this.getLatestData();
    this.getEurRate("BTC","EUR");
    this.getEurRate("BLX","EUR");
    this.getEurRate("ETH","EUR");
    this.getEurRate("USDT","EUR");
    this.getEurRate("XCOIN","EUR");
     this.getEurRate("BCH","EUR");
    this.getEurRate("LTC","EUR");
    this.getEurRate("EOS","EUR");
    this.getEurRate("XLM","EUR");
    this.getEurRate("XRP","EUR");
     this.getEurRate("PEG","EUR");
     this.getEurRate("BLOXDAO","EUR");
   
   this.getGbpRate("BTC","GBP");
    this.getGbpRate("BLX","GBP");
    this.getGbpRate("ETH","GBP");
    this.getGbpRate("USDT","GBP");
    this.getGbpRate("XCOIN","GBP");
     this.getGbpRate("BCH","GBP");
    this.getGbpRate("LTC","GBP");
    this.getGbpRate("EOS","GBP");
    this.getGbpRate("XLM","GBP");
    this.getGbpRate("XRP","GBP");
     this.getGbpRate("PEG","GBP");
     this.getGbpRate("BLOXDAO","GBP");
    
     this.getUsdRate("BTC","USD");
    this.getUsdRate("BLX","USD");
    this.getUsdRate("ETH","USD");
    this.getUsdRate("USDT","USD");
    this.getUsdRate("XCOIN","USD");
     this.getUsdRate("BCH","USD");
    this.getUsdRate("LTC","USD");
    this.getUsdRate("EOS","USD");
    this.getUsdRate("XLM","USD");
    this.getUsdRate("XRP","USD");
     this.getUsdRate("PEG","USD");
     this.getUsdRate("BLOXDAO","USD");

     this.getJpyRate("BTC","JPY");
    this.getJpyRate("BLX","JPY");
    this.getJpyRate("ETH","JPY");
    this.getJpyRate("USDT","JPY");
    this.getJpyRate("XCOIN","JPY");
     this.getJpyRate("BCH","JPY");
    this.getJpyRate("LTC","JPY");
    this.getJpyRate("EOS","JPY");
    this.getJpyRate("XLM","JPY");
    this.getJpyRate("XRP","JPY");
     this.getJpyRate("PEG","JPY");
     this.getJpyRate("BLOXDAO","JPY");

    this.getKrwRate("BTC","KRW");
    this.getKrwRate("BLX","KRW");
    this.getKrwRate("ETH","KRW");
    this.getKrwRate("USDT","KRW");
    this.getKrwRate("XCOIN","KRW");
     this.getKrwRate("BCH","KRW");
    this.getKrwRate("LTC","KRW");
    this.getKrwRate("EOS","KRW");
    this.getKrwRate("XLM","KRW");
    this.getKrwRate("XRP","KRW");
     this.getKrwRate("PEG","KRW");
     this.getKrwRate("BLOXDAO","KRW");

    this.getPhpRate("BTC","PHP");
    this.getPhpRate("BLX","PHP");
    this.getPhpRate("ETH","PHP");
    this.getPhpRate("USDT","PHP");
    this.getPhpRate("XCOIN","PHP");
     this.getPhpRate("BCH","PHP");
    this.getPhpRate("LTC","PHP");
    this.getPhpRate("EOS","PHP");
    this.getPhpRate("XLM","PHP");
    this.getPhpRate("XRP","PHP");
     this.getPhpRate("PEG","PHP");
     this.getPhpRate("BLOXDAO","PHP");

     this.getAudRate("BTC","AUD");
    this.getAudRate("BLX","AUD");
    this.getAudRate("ETH","AUD");
    this.getAudRate("USDT","AUD");
    this.getAudRate("XCOIN","AUD");
     this.getAudRate("BCH","AUD");
    this.getAudRate("LTC","AUD");
    this.getAudRate("EOS","AUD");
    this.getAudRate("XLM","AUD");
    this.getAudRate("XRP","AUD");
     this.getAudRate("PEG","AUD");
     this.getAudRate("BLOXDAO","AUD");
    



  }
  
}
</script>
<style scoped>
.topCrypto {
   float: left;
   border-radius: 10px;
    background: rgb(2, 11, 54);
    background-image: linear-gradient(
      to right,
      #862f7e 0%,
      #681f6f 38%,
      #56146686 73%,
      #16116356 97%
    ) !important;
    color: white;
    border: none;
  
  }
  
  .main-container{
		padding: 1px 16px;
		height: auto;
    width: auto !important;
    margin: 0 auto !important;
    justify-content: center;
    
	}
  .wrapper{
     width: auto !important;
    height: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flex-container{
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto !important;
    justify-content: center;
  }
  .flex-box{
    margin: 10px !important;
    padding: 10px !important;
    margin: 0 auto !important;
    justify-content: center;
  }

  	@media screen and (min-width: 280px) and (max-width: 768px) {
     .main-wrapper{
       margin: 0 auto;
       display: flex;
       justify-content: center !important;
     }
     .flex-container{
         
          flex-wrap: nowrap !important;
        }
        .currency{
          width: 100px;
        }
     

    }




</style>
<template>
    <div id="body" class="container-fluid footer d-flex text-center flex-wrap">
    
        <p id="footerText" class="poweredBy">xCoin.net</p>
        <p id="footerText" class="margined">© Copyright 2022 xCoin.net </p>
        
        
        <div class="socmed d-flex">
                <p class="pt-3 btn text-small" type="button" onclick="window.open('mailto:support@xcoin.net')">Contact Us</p>
                <button class="btn mr-sm-2" type="button" onclick="window.open('https://www.facebook.com/xCoin.net')"><img src="../assets/images/fb.png"/></button>
                <button class="btn mr-sm-2" type="button" onclick="window.open('https://www.instagram.com/xcoin_net/')"><img src="../assets/images/ig.png"/></button>
        </div>
    
      </div>
 
</template>

<script>
export default {
  name: 'Footer',
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #body {
        background-color:black;
        color:gray;
        display: flex;
        text-align: center;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 50px;
        bottom: 0 !important;
        position: fixed;

        
    }
    #footerText {
        font-family:'PoppinsMedium';
        padding-top: 5px;
        font-size:15px;
        
    }

    #footerImg {
      display: inline;
      width:1rem;
      margin-right:5px;
    }
    .socmed p{
      display: flex;
      justify-content: center;
        font-size:15px;
        color: #e2e2e2;
    }
</style>
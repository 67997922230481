<template>
     <div class="backdrop">
      <div class="b-modal">
        <div class="modal-header">
          <div class="modal-title">
            <img src="@/assets/images/logo.png" />
          </div>
          <p class="text-white"><span @click.self="closeModal">x</span></p>
        </div>
        <div>
          <form class="form" v-on:submit.prevent="onLogin" v-if="login.state == 'login'">
             
                <h4 class="text-center text-dark">LOG IN</h4>
                <div class="danger" v-if="errors.login.show">
                    {{ errors.login.msg }}
                </div>
                <div class="form-row container text-center">
                    <div class="btn-group text-center ">
                        <button class="btn mobile-btn mx-2 mr-sm-2" type="button"  v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')" >Mobile Number</button>
                        <button class="btn email-btn mr-sm-2" type="button" v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
                    </div>
                </div>
                <div class="form-group" v-if="selected =='email'">
                    <label for="formGroupExampleInput">Enter your Email Address:</label>
                    <input type="text" class="form-control mb-3" id="formGroupExampleInput" v-model="login.loginId">
                    <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>

                <div class="form-group mobile-container" v-if="selected =='mobile' ">
                    <label for="loginId">Enter your Mobile Number:</label> 
                    <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn" type="button" v-on:click="toggleCountryList">
                        <img class="default-flag" :src="require('../../assets/signup_flags/' + country + '.png' )">
                        <span class="text-dark">{{ mobileCode }}</span>
                      </button>
                    </div>
                    <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)">
                  </div>
        
                  <div class="country-list" v-if="showCountry">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l" v-on:click="selectCountry(l)">
                          <div >
                            <img class="flag-list-img" :src="require('../../assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
        
                          <div class="country-name"> <span> {{ l.country }} </span> </div>
                          
                        </div>
                        
                      </div>
                    </div>
                     <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
                </div>
        
        
        
                <div class="form-group">
                    <label for="exampleFormControlInput1">Enter you 6-digit PIN for Security:</label>
        
                    <div class="input-group mb-3">
                      <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
                      <div class="input-group-append">
                        <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword()">{{ hideOrShow }}</button>
                      </div>
                    </div>
                    <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>

                    <div>
                    <a href="#" v-on:click="toggleLogin('forgot-pass')" class="forgot">Forgot PIN</a>
                  </div>
        
                </div>

                <label for="exampleFormControlInput1">Answer Math Equation Correctly:</label>
                <div id="captcha" class="d-flex justify-content-center mx-auto">
                  
                    <div>
                        <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                    </div>
                    <div class="captcha">
                        <input type="text" class="form-control captcha" v-model="captchaSum"  @keypress="isNumber($event)">
                    </div>
                </div>


                <div class="form-group ">
                    <button type="submit" class="btn btn-main" id="loginBtn">LOG IN</button>
                </div>
                <div class="form-group ">
                    <button type="button" class="btn btn-primary signupBtn" v-on:click="showSignUp()">Create an account NOW</button>
                </div>

            </form>

                  <form class="form" v-on:submit.prevent="onForgotPassword" v-if="login.state == 'forgot-pass'">

                  <div class="danger" v-if="errors.login.show">
                        {{ errors.login.msg }}
                    </div>
                      <div class="form-group">
                          <label for="formGroupExampleInput">Email Address or Mobile Number:</label>
                          <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
                      </div> 

                      <div class="form-group submit-button text-center pt-3">
                          <button class="btn btn-main" id="forgotBtn">Submit </button>
                      </div>

                      <a href="#" v-on:click="toggleLogin('login')" class="forgot">Back to login</a>

                  </form>



      </div>
    </div>
    <SetupProfile v-if="nullName" :userId="userId"></SetupProfile>
 
  </div>
        

</template>

<script>
  import SetupProfile from '../modals/SetupProfile.vue'
  export default {
    name: 'Login',
    components:{ SetupProfile},
    data: function() {
      return  {
        otpArr: [], 
        selected: 'mobile',
        country: 'PH',
        mobileCode: '63',
        showCountry: false,
        login: {
          state: 'login',
          showPassword: false
        },
        userId: '',
        accessToken: '',
        nullName: false,
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            otp: "",
            loginPass: ""
          }
        },
        list: {
          mobileCodes: []
        },
         hideOrShow: "Show",
         captchaString: "",
        captchaSum: "",
        captchaImgSrc: "",
      }
    },
    methods: {
       captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/login/captcha?fontColor=4e1163',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data

                })
                .catch((error) => {
                    // Error code goes here
                    this.errors.login.msg = error.response.data.msgText
                    setTimeout(()=>{
                      this.clearError()
                    },4000);
                    
                });
        },
      showSignUp(){
        this.$emit("openSignUp");
      },
      closeModal() {
        this.$emit("close");
      },
      
        onForgotPassword: function() {
        this.errors.login.show = false;
        const params = {
            siteHost: "xcoin-store.blx.live",
            loginId: this.login.loginId, 
        }

        const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data,
            url: '/cx/processforgotpin',
        };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              
              this.login.loginId = "";
          }).catch((err) => {
            console.log(err.response.data)
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(()=>{
                      this.clearError()
                    },4000);
          })

    },
       toggleLogin: function(state) {
        this.errors.login.show = false;
        this.errors.login.msg = "";
        this.login.state = state;
      },
    
      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },
      toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;

        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"

        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
      toggleLoginOption(selected) {
        this.selected = selected;
        this.login.loginId = "";

        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';

        if(selected == 'email') {
          this.login.loginId = "";
        }
      },
      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
              console.log(err);

          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },
      validateEmail: function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      clearError(){
                this.errors.login.loginId = ""
                this.errors.login.loginPass = ""
                this.errors.login.msg = ""


      },
      onLogin: function() {
            let hasError = false;

            if(this.selected == 'mobile') {
                if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                this.errors.login.loginId = "Please provide a valid  Mobile no.";
                hasError = true;
                setTimeout(()=>{s
                      this.clearError()
                    },4000);
                } 

                if(this.login.loginId.length < 10) {
                this.errors.login.loginId = "Please provide a valid  Mobile no.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                } 

                if(this.login.loginId.substring(0,1) === '0') {
                this.errors.login.loginId = "Please remove 0 in front of your mobile number";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                }
            } 

            if(this.selected == 'email') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                this.errors.login.loginId = "Please provide a valid Email Address.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                } 

                if(!this.validateEmail(this.login.loginId)) {
                
                this.errors.login.loginId = "Please provide a valid Email Address.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                }
            }


            if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
              this.errors.login.loginPass = "Please provide password.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
              }  else {
              if(this.login.loginPass.length < 6) {
                  this.errors.login.loginPass = "PIN must be 6-digits.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
              }
            }



            if(hasError) {
                return;
              }

              this.errors.login.show = false;

              let loginId = ""; 

              if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
              } else {
                loginId = this.login.loginId
              }
        

            const params = {
              loginHost: "xcoin-store.blx.live",
              loginId: loginId,
              pin: this.login.loginPass,
              captcha: this.captchaSum
            }

            const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');


            const options = {
             method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded'},
              data,
              url: '/cx/login/pin',
            };

            this.axios(options)
            .then((response) => {
              localStorage.setItem("tokenId", response.data.tokenId);
              console.log(localStorage.getItem("tokenId") )
              localStorage.setItem("userId", response.data.userId)
              this.userId = localStorage.getItem("userId")
              this.$router.push({name: 'Dashboard', params: { userId: this.userId}})
              window.location.reload();

               
            }).catch((err) => {
                this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                 
          })
         },

        
   

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
    },

    beforeMount(){
      this.getMobileCodes();
      
      
    },
    mounted(){
      this.captcha();
    },

    watch: {

    }

    }
</script>

<style scoped>
/*Modal*/
.b-modal{
    width: 500px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
  }
.modal-header{
    width: 100%;
    background: rgb(2,11,54);
    background: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
.b-modal h4{
    padding: 15px;
  }
    .logo{
        display: flex;
        justify-content: center;
        margin: 50px auto !important;
    }
    .logo img{
        width: 10rem;
    }

    .b-card{
      width: 50em;
    }

    .email-btn{
        background-color: transparent !important;
        border: transparent !important;
        font-size: 1rem !important;
        padding: 10px 15px;
        color: black !important;
        }
    .email-btn:hover{
        color: #7000a3 !important;
    }
    .email-btn:focus{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .email-btn.active{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .mobile-btn{
        background-color: transparent !important;
        border-color: transparent !important;
        font-size: 1rem !important;
        padding: 10px 20px;
        color: black;
    }
    .mobile-btn:hover{
        color: #7000a3 !important;
    }
    .mobile-btn:focus{
        border-bottom: solid 1px #7000a3 !important;
    }
    .mobile-btn.active{
        border-bottom: solid 1px #7000a3 !important;
        outline: none !important;
        color: #7000a3 !important;
    }
    .form{
        width: 80%;
        display: block;
        justify-content: center;
        margin: 0 auto !important;
    }

    .signupBtn {
        border: 1px solid #481a69;
        color: white;
        width: 100%;
        margin-top: 0.5em;
    }
    .signupBtn:hover{
        background-color:#481a69;
        color: white;
    }
    .btn-main{
        border: 1px solid #481a69;
        color: black;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }
    .forgot {
        display: flex;
        justify-content: end;
    }
    a {
        color: #481a69;
        text-decoration: underline;
    }
    .form-control{
        background-color: lightgray;
    }
    .mobile-container {
        position: relative;  
    }
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .btn{
        border: 1px solid #481a69;
        
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: #481a69;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }

    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
    }
    .country-list .row:hover {
      background: darken(#481a69, 10%);
    }
    .country-name {
      margin-left: 0.5em;
    }
    .country-name span {
        font-size: 0.9em;
    }

    .flag-list-img {
        width: 25px;
    }
    .email-or-mobile {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1em;
    }
    .email-or-mobile .btn {
          color: #481a69;
          border: none;
          outline: none;
          box-shadow: none;
          border-radius: 0;
    }
    .btn-primary{
      background-color: #481a69;
      color: white;
    }
    .email-or-mobile .btn.active {
        color: #481a69;
        border-bottom: 1px solid #481a69;
      }
    
    .email-or-mobile .button {
        color: #481a69;  
    }

    .wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2em;
        width: 25em;
      }
    .wrapper form {
        color: #FFF;
    }
    .otp-wrapper {
        align-items: center;
        display: flex;
        width: 60%;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 1em;
    } 
    .otp-wrapper div {
        margin: 0 .25em;
    }
    .otp-wrapper .otp-input {
        width: 47px;          
        text-align: center;
        border-radius: 6px;
        box-shadow: none;
        font-size: 2em; 
        border: 1px solid  #262d33;
        box-shadow: none;
        outline: none; 
        background: #FFFFFF;
        color: #262d33;
        border-radius: 6px;
        height: 60px;
    }
    .footer{
      display: flex;
      justify-content: center;
      bottom: 0 !important;
    }
    .alert-danger{
      background-color: none !important;
      border: none !important;
    }
      	@media screen and (min-width: 280px) and (max-width: 768px) {
        .b-modal{
          width: 300px;
          scroll-behavior: auto;
          margin: 10px auto;
        }
        .mobile-container .text-center, label{
          font-size: 10px !important;
        }
        #captcha img{
          width: 8rem !important;
        }
        .captcha .form-control {
          padding: 5px !important;
          border: 1px solid #7000a3;
          border-radius: 4px;
          font-size: 1rem !important;
          text-align: center;
        }
      }



</style>
<template>
<div class="bg">
    <div class="e-modal container text-white">
        <div class="b-card">
            <h4 class="pt-5">Profile Information</h4>
                <div class="alert alert-danger" v-if="errors.profile.show">
                    {{ errors.profile.msg }}
                </div>

            <form class="form" v-on:submit.prevent="submitProfile">
                <div class="form-group">
                    <label for="firstName">First Name:</label>
                    <input type="text" class="form-control mb-3"  id="firstName" v-model="profile.firstName">
                    <p class="help-block"> </p>

                    <label for="lastName">Last Name:</label>
                    <input type="text" class="form-control mb-3"  id="lastName" placeholder="" v-model="profile.lastName">
                    <p class="help-block"> </p>
                </div>
                <div class="form-group ">
                    <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                </div>
              </form>
              <div class="logo">
                <img src="../../assets/images/logo.png"/>
            </div>
            </div>
            
    </div>
    <Dashboard class="d-none" :userId="userId"/>
</div>
</template>

<script>
    import qs from 'querystring'
    import Dashboard from '@/components/Dashboard.vue'

  export default {
    name: 'SetupProfile',
    components: {
        Dashboard
    },
    data: function() {
      return  {
        userId: "",
        profile: {
          firstName: "",
          lastName: "",
        },
        errors: {
          profile: {
            show:false,
            msg: ""
          }
        }
      }
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
      show() {
        return this.$store.state.show;
      },
      list() {
        return this.$store.state.list;
      },
    },
    methods: {
        // checkSession: function() {

        // const options = {
        //   method: 'POST',
        //   headers: { 'content-type': 'application/x-www-form-urlencoded'},
        //   url: '/cx/sessioncheck',
        // };

        // this.axios(options)
        //   .then((response) => {

        //       console.log(response)
        //     }).catch((err) => {
                
        //       console.log(err.response.data)     

        //       if(err.response.data.msgCode == '00001') {
        //         window.location.href = "/#/";
        //       }  
        //     })
        // },
        submitProfile: function(){
               
            if(this.profile.firstName == "" || this.profile.firstName == null || this.profile.firstName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "First name is required";
            return false;
            }

            if(this.profile.lastName == "" || this.profile.lastName == null || this.profile.lastName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Last name is required";
            return false;
            } 


            this.errors.profile.show = false;
            const params = {
            firstName: this.profile.firstName,
            lastName: this.profile.lastName
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/submitname',
            };

            this.axios(options)
            .then((response) => {
            console.log(response.data)
             
              console.log(localStorage.getItem("tokenId") )
              localStorage.setItem("userId", response.data.userId)
              this.userId = localStorage.getItem("userId")
              this.$router.push({name: 'Dashboard', params: { userId: this.userId}})
               
            }).catch((err) => {
              console.log(err.response.data)     

                if (err.response.data) { 
                this.errors.profile.show = true;
                if(err.response.data.fieldErrors.length > 0) {
                    err.response.data.fieldErrors.forEach((error) => {
                    switch (error.field) {
                        default:
                        this.errors.profile[error.field] = error.defaultMessage;
                        break;
                    }
                    }); 
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage;

                }  else {
                    this.errors.profile.msg = err.response.data.msgText;
                }
            } 
            })
        },

  

     },

  //  beforeMount(){
  //        this.checkSession();
  //   },
 }
</script>

<style scoped>
    .bg{
      background: rgb(2,11,54);
      width: 100%;
      height: 100vh !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
   }
    .logo{
        justify-content: center;
        text-align: center;
        display: flex !important;
    }
    .logo img{
        background-color: transparent;
        width: 7rem;
    }
    .e-modal{
            margin-top: 20px;
            background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-bottom: 5em;
            width: 50em;
            border-radius: 10px !important;
            box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.336), 0 9px 46px 8px rgba(0, 0, 0, 0.356), 0 11px 15px -7px rgba(0, 0, 0, 0.295)
        }

    .form{
        padding: 20px;
        width: 100%;
        display: block;
        justify-content: center !important;
        text-align: center;
        margin: 0 auto !important;
    }
    .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        color: white;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }

</style>
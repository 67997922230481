<template>
<div>
   <div class="col-lg-3 col-md-6 mt-3">
   <div class="prodcard">
      <div class="img-container">
        <img
          :src="require('../assets/crypto/' + rate.fromCurrency + '.png')"
          :alt="rate.data"
          class="store-img"
          img-top
        />
      </div>
      <span class="store-item-icon">
        <b-icon icon="cart" scale="1" shift-h="75" shift-v="100"></b-icon>
      </span>
      <div class="body-details g-0">
        <div class="pt-1 text-center">
          <h5 class="text-center">
            {{ rate.fromCurrency != 'BLOXDAO' ? rate.fromCurrency: 'BLOX' }} | {{rate.toCurrency}}
          </h5>
          </div>
          <div class="pt-1">
          <h6 class="text-center">
            {{ formatAmount(rate.convertedAmount) }}
          </h6>
        </div>
        <div class="pt-1">
<!--         
      <b-button @click="loginModal" class="btn-success" align="center">BUY</b-button> -->
           
        </div>
      </div>
    </div>
   
  </div>
 
</div>
</template>
<script>
    import numeral from 'numeral'
   

export default {
  props: ["rate"],
  name: 'ProductCard',
  components:{
    
  },
  data() {
    return {
        assetInfo: [],
        viewProduct: false,
        userId:'',
          showLoginModal: false,
          showSignupModal: false,
    }
  },
  methods: {
    formatAmount: function(amount) {
			return numeral(amount).format('0,0.00') 
		},
     loginModal() {
      this.showLoginModal = !this.showLoginModal;
      this.showSignupModal = false
    },
    signupModal() {
      this.showSignupModal = !this.showSignupModal;
      this.showLoginModal = false
    },
  }
};
</script>

<style scoped>
.prodcard {
    background-image: linear-gradient(
            180deg,
            rgba(249, 250, 253, 1) 29%,
            rgba(235, 238, 249, 1) 59%,
            rgba(214, 220, 244, 1) 88%
          );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 12px;
     width: 11rem;
    height: 15rem;
    text-align: center;
    padding: 20px;
    margin: 10px !important;
  }
  .prodcard:hover{
    background-image: linear-gradient(
            180deg,
            rgb(236, 241, 254) 29%,
            rgb(211, 220, 249) 59%,
            rgb(165, 179, 234) 88%
      );
      box-shadow: 5px 10px 30px rgba(0,0,0,0.3);

  }
  .card-body {
    margin: 10px 10px !important;
    align-items: center;
    text-align: center !important;
  }
  
  .img-container {
    padding: 10px 0px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    background-color: none !important;
  }
  .store-img {
    width: 5rem;
    margin: auto auto;
    transition: all 1s ease-in-out;
  }
  .img-container:hover .store-img {
    transform: scale(1.2);
  }
  .body-details{
    left: 0 !important;
    right: 0 !important;
    display: block;
    justify-content: center !important;
    margin: 0 auto!important;
    flex-direction: row;
    height: 120px;
    width: auto;
    text-align: center !important;
  }
  .btn-success{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color:   rgb(43, 79, 224) 88% !important;
    border: none !important;
  }
  .pt-1{
    align-content: space-between;
  } 

     
       @media screen and (min-width: 280px) and (max-width: 480px) {
         .prodcard {
            width: 5rem;
            height: 9rem;
           
          }
           .store-img {
              width: 2rem;
             
            }
          .store-item-icon{
            display: none;
          }
          h5, h6{
            font-size: 1rem;
          }
       }


</style>
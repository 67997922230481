<template>
<div class="top pt-5 pb-5">
     <div class="d-modal">
      <div class="modal-header">
        <div class="modal-title">
          <img src="@/assets/images/logo.png" />
        </div>
        
      </div>
      <div class="pt-3">
        <form class="form container">
            <h4 class="pt-5 text-center pb-3">Your transaction has failed.</h4> 
            <div class="col-12 d-flex justify-content-between my-3 text-left">
                  <div class="col-7">
                    <label for="walletAddress">You were buying:</label>
                    <p class="text">{{cryptoAmount}} {{crypto}}</p>
                    <label for="paymentMethod">Payment Method:</label>
                    <p class="text">{{paymentMethod}}</p>
                    <label for="message">Comment:</label>
                    <p class="text">{{comment}}</p>
                    
                    
                    <label for="reference">Reference Number:</label>
                    <p class="text">{{referenceId}}</p>
                  </div>
                  <div class="col-6">
                    <label for="message">Status:</label>
                    <p class="text">{{paymentStatus}}</p>
                    <label for="walletAddress">Wallet Address:</label>
                    <p class="text">{{walletAddress}}</p>
                    
                    <label for="timestamp">Date and time:</label>
                    <p class="text">{{formatDate(paymentDate)}}</p>
                  </div>
                </div>
            <b-button type="button" class="btn btn-primary btn-block"  @click="done">DONE</b-button>
        </form>
      </div>
    </div>
</div>
</template>
<script>


export default {
  props:["txnId"],
  name: "Failed",
  components: {
   
    },
  data() {
    return {
     crypto: '',
         paymentMethod: '',
         paymentDate:'',
         paymentStatus:'',
         fiat:'',
         cryptoAmount:'',
         fiatAmount:'',
         walletAddress:'',
         comment:'',
         referenceId:'',
         image:'',
        

    };
  },
  
  methods: {
    formatDate: function(time){
        return new Date(time).toLocaleString();
      },
    getTxnDetails(){
          console.log (this.$route.params.txnId)
          const params = {
               txnId: this.$route.params.txnId
             }
             const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              params,
              url: '/cx/txn/buycrypto/details',
            };

                this.axios(options)
                  .then((response) => {
                    console.log(response.data)
                    this.userId = response.data.entityId
                     this.crypto = response.data.targetCurrency
                    this.paymentMethod = response.data.paymentMethod
                    this.paymentDate = response.data.paymentDate
                    this.paymentStatus = response.data.paymentStatus
                    this.fiat = response.data.sourceCurrency
                    this.cryptoAmount = response.data.targetAmount
                    this.fiatAmount = response.data.sourceAmount
                    this.walletAddress = response.data.walletAddress
                    this.comment = response.data.txnComment
                    this.referenceId = response.data.paymentRef
                    this.image = 'https://store.xcoin.ph/assets/crypto/' + this.crypto + '.png'
                    })
            },
        

  
    done(e) {
      e.preventDefault();
      this.$router.push({name: 'TransactionHistory', params: { userId: this.userId}})
      
      
    },
    clearTimer(){
        localStorage.removeItem("min");
        localStorage.removeItem("sec");
      }
  },
  mounted(){
    this.getTxnDetails();
    this.clearTimer();
  }
};
</script>
<style scoped>
.top{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgb(2,11,54);
}
.d-modal{
    display: block;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto !important;
    margin: auto auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 10px;
  }

.btn-secondary {
  background-color: rgb(162, 113, 219) !important;
  border: none;
  color: white;
}
.btn-secondary:hover {
  background: grey;
  color: white;
}
.btn-block{
  display: flex;
  justify-content: center !important;
  margin: 0 auto;
  text-align: center;
  width: 50%;
}
label{
  font-size: 30px;
}
.text{
  font-size: 30px !important;
}
 @media only screen and (max-device-width: 480px) {
   .d-flex {
     display:block !important;
   }
   label{
    font-size: 10px;
    padding: 0 !important;
    margin: 0 !important;
    }
   .text{
     padding: 0 !important;
     margin: 0 !important;
     font-size: 10px !important;
    }
    .pt-3{
      padding: 0 !important;
      margin: 0 !important;
    }
   

 }
</style>

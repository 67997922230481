<template>
  <div class="col-lg-3 col-md-6 mt-3">
    <div class="prodcard" v-if="product">
      <div class="img-container">
        <img
          :src="require('../assets/crypto/' + product.fromCurrency + '.png')"
          :alt="product.data"
          class="store-img"
          img-top
        />
      </div>
      <span class="store-item-icon">
        <b-icon icon="cart" scale="1" shift-h="75" shift-v="100"></b-icon>
      </span>
      <div class="body-details g-0">
        <div class="pt-1">
          <h6>
            {{ product.fromCurrency }} | {{product.toCurrency}}
          </h6>
          </div>
          <div class="pt-1">
          <p class="text-center">
            {{ formatAmount(product.convertedAmount)}}
          </p>
        </div>
        <div class="pt-1">
        
        <router-link :to="{ name: 'Cart', params: {userId: userId}  }"> <b-button @click="addToCart()" class="btn-success" align="center">BUY</b-button></router-link>
           
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
    import numeral from 'numeral'

export default {
  props: ["product"],
  name: 'ProductCard',
  data() {
    return {
        assetInfo: [],
        viewProduct: false,
        userId: this.$route.params.userId,
    }
  },
  created: function(){
   let loaded = JSON.parse(localStorage.getItem('user'));

    if(loaded) {
          this.accessToken = loaded.accessToken
          this.userId = loaded.userId
      }
  },
  methods: {
    formatAmount: function(amount) {
			return numeral(amount).format('0,0.00') 
		},
    viewProductInfo:function(content){
        this.$emit('viewProduct')
    },
    close(){
      this.viewProduct = false
    },
    addToCart(){
      let myCart = {
        userId: this.$route.params.userId,
        product: this.product,
        value: 1,
        fee: 0.0001,
        image: 'https://store.xcoin.net/assets/crypto/' + this.product.fromCurrency + '.png',
      };
      localStorage.setItem('cart', JSON.stringify(myCart));
      console.log (myCart);

          this.$router.push({name: 'Cart', params: { userId: this.$route.params.userId}})
          // history.pushState(null, null, location.href);
          //   window.onpopstate = function () {
          //       history.go(1);
          // };

    },
    //  addToCart(crypto, price, fee){
    //      this.product.fromCurrency = crypto
    //      this.product.image = 'https://store.xcoin.ph/assets/crypto/' + this.product.fromCurrency + '.png',
    //      this.product.convertedAmount = price
    //      this.fee = fee,
    //      this.userId = userId

    //      let myCart = {
    //        productId: this.product.fromCurrency,
    //        userId: this.userId,
    //        value: 1,
    //        price: this.product.convertedAmount,
    //        image: this.product.image,
    //        fee: this.fee
          
    //      };
    //      localStorage.setItem('cart', JSON.stringify(myCart));
    //      console.log(myCart);

    //      this.$router.push({name: 'Cart', params: { userId: this.$route.params.userId}})
    //       history.pushState(null, null, location.href);
    //         window.onpopstate = function () {
    //             history.go(1);
    //       };

    //     },

  }
};
</script>

<style scoped>
.prodcard {
    background-image: linear-gradient(
            180deg,
            rgba(249, 250, 253, 1) 29%,
            rgba(235, 238, 249, 1) 59%,
            rgba(214, 220, 244, 1) 88%
          );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid white;
    border-radius: 12px;
     width: 12rem;
    height: 17rem;
    text-align: center;
    padding: 20px;
    margin: 10px !important;
     transition: all 1s ease-in-out;
  }
   .prodcard:hover{
    background-image: linear-gradient(
            180deg,
            rgb(236, 241, 254) 29%,
            rgb(211, 220, 249) 59%,
            rgb(165, 179, 234) 88%
      );
      box-shadow: 5px 10px 30px rgba(0,0,0,0.3);
      transform: scale(1.1);

  }
  .card-body {
    margin: 10px 10px !important;
    align-items: center;
    text-align: center !important;
  }
  
  .img-container {
    padding: 10px 0px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    background-color: none !important;
  }
  .store-img {
    width: 5rem;
    margin: auto auto;
    transition: all 1s ease-in-out;
  }
  .img-container:hover .store-img {
    transform: scale(1.2);
  }
  .body-details{
    left: 0 !important;
    right: 0 !important;
    display: block;
    justify-content: center !important;
    margin: 0 auto!important;
    flex-direction: row;
    height: 120px;
    width: auto;
    text-align: center !important;
  }
  .btn-success{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color:   rgb(43, 79, 224) 88% !important;
    border: none !important;
  }
  .pt-1{
    align-content: space-between;
  } 

     
       @media screen and (min-width: 280px) and (max-width: 480px) {
         .prodcard {
            width: 8rem;
            height: 12rem;
           
          }
           .store-img {
              width: 2rem;
             
            }
          .store-item-icon{
            display: none;
          }
          h5, h6{
            font-size: 1rem;
          }
       }
       	@media screen and (min-width: 480px) and (max-width: 768px) {
            .prodcard {
            width: 12rem;
            height: 17rem;
           
          }
         }
         	@media screen and (min-width: 768px) and (max-width: 2100px) {
            .prodcard {
            width: 12rem;
            height: 19rem;
           
          }
         }




</style>